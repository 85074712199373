import {get, post,put} from './base'
import qs from "qs";

//获取会员excel列表
export function getMemberLists(pageNum){
    return get('/v3/logg',{pageNum})
}
//上传会员excel
export function postMemberExcel(file){
    return post('/v3/upload',file,{headers:{'Content-Type':'multipart/form-data'}})
}
//导入会员信息
export function postMembers(data){
    return post('/v3/member',qs.stringify(data))
}
//获取新增的会员信息
export function getExcelList(id,pageNum){
    return get('/v3/members/' + id,{pageNum})
}
//会议title
export function getCaseTitle(){
    return get('/v3/casesS')
}
//同步学分
export function postCredit(data){
    return post('/v3/member/credit', qs.stringify(data))
}
//同步缴费
export function postPay(data){
    return post('/v3/member/record',qs.stringify(data))
}
//同步病例
export function postCases(data){
    return post('/v3/member/template',qs.stringify(data))
}
//病例模板title
export function getCaseTemplate(){
    return get('/v3/member/case/template')
}

//会员认证审核
export function getAuditInfo(pageNum,status,name,hospital){
    return get('/v3/authentications',{pageNum,status,name,hospital})
}
export function getAuditId(id){
    return get('/v3/authentication/' +id)
}
export function putAudit(id,data){
    return put('/v3/authentication/' + id ,data)
}
